import { apiGet, apiPost } from '../api';
import { createEvent, createStore } from 'effector';
import { IFridge } from '../types/fridges';
import { useStore } from 'effector-react';
import { $selectedCompanyId } from './companies';
import { useCallback, useMemo } from 'react';

export const updateFridges = createEvent<IFridge[]>();

export const $fridges = createStore<IFridge[]>( [] )
  .on( updateFridges, ( state, payload ) => payload );

const hashMap: { [ key: string ]: IFridge } = {};

export const useFridges = ( status = false ) => {
  const _fridges = useStore( $fridges );
  const companyId = useStore( $selectedCompanyId );

  return useMemo( () => {
    return _fridges.filter( f => {
      if ( status && !f.status ) return false;
      hashMap[ f.id ] = f;
      return !companyId || f.companyId === companyId;
    } );
  }, [ companyId, _fridges.length ] );
};

export const useFridgeById = () => {
  const fridges = useFridges();
  return useCallback( ( id: string ) => {
    return hashMap[ id ];
  }, [ fridges.length ] );
};

export const listFridges = async () => {
  const response = await apiGet<IFridge[]>( {
    url: '/admin/fridges'
  } );

  updateFridges( response );

  return response;
};

export const getFridge = async ( id?: string | number ) => {
  if ( !id ) return;

  return apiGet<IFridge>( {
    url: `/admin/fridges/${ id }`
  } );
};

export const postFridge = async ( id: string | number, data: any ) => {
  return apiPost<IFridge>( {
    url: `/admin/fridges/${ id }`,
    postData: data
  } );
};

export const openFridge = async ( id: string | number ) => {
  return apiPost<any>( {
    url: `/admin/fridges/${ id }/open`,
  } );
};

export const getIpAddress = async ( id?: string | number ) => {
  return apiGet<any>( {
    url: '/admin/fridges/ipAddress'
  } );
};

export const getVpnLogin = async ( id?: string | number ) => {
  return apiGet<any>( {
    url: '/admin/fridges/vpnLogin'
  } );
};
